import PropTypes from 'prop-types';
import { useContext } from 'react';
// material-ui
import { Divider, List, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports
import AuthContext from 'context/auth/AuthContext';
import NavItem from '../NavItem';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
    const theme = useTheme();

    const context = useContext(AuthContext);
    const { userDetails } = context;

    return (
        <>
            <List
                subheader={
                    item.title && (
                        <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
                            {item.title}
                            {item.caption && (
                                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                    {item.caption}
                                </Typography>
                            )}
                        </Typography>
                    )
                }
            >
                {item.children?.map((menu) => {
                    if (Object.keys(userDetails).length != 0) {
                        if (menu.id == 'dashboard' && userDetails.result.role != 'Super Admin') {
                            return <NavItem key={menu.id} item={menu} level={1} />;
                        }
                        if (menu.id == 'companies' && userDetails.result.role == 'Super Admin') {
                            return <NavItem key={menu.id} item={menu} level={1} />;
                        }
                        if (menu.id == 'users-list' && (userDetails.result.role == 'Admin' || userDetails.result.role == 'Company')) {
                            return <NavItem key={menu.id} item={menu} level={1} />;
                        }
                        if (menu.id == 'manager-users-list' && userDetails.result.role == 'Manager') {
                            return <NavItem key={menu.id} item={menu} level={1} />;
                        }
                        if (menu.id == 'check-in' && userDetails.result.role == 'Employee') {
                            return <NavItem key={menu.id} item={menu} level={1} />;
                        }
                        if (menu.id == 'user-check-in-list' && userDetails.result.role == 'Manager') {
                            return <NavItem key={menu.id} item={menu} level={1} />;
                        }
                        if (
                            menu.id == 'all-check-in-list' &&
                            (userDetails.result.role == 'Admin' || userDetails.result.role == 'Company')
                        ) {
                            return <NavItem key={menu.id} item={menu} level={1} />;
                        }
                        if (
                            menu.id == 'main-owner-view' &&
                            (userDetails.result.role == 'Admin' ||
                                userDetails.result.role == 'Manager' ||
                                userDetails.result.role == 'Company')
                        ) {
                            return <NavItem key={menu.id} item={menu} level={1} />;
                        }

                        if (
                            menu.id == 'owner-view' &&
                            (userDetails.result.role == 'Admin' ||
                                userDetails.result.role == 'Manager' ||
                                userDetails.result.role == 'Company' ||
                                userDetails.result.role == 'Employee')
                        ) {
                            return <NavItem key={menu.id} item={menu} level={1} />;
                        }
                        if (menu.id == 'assign-users' && (userDetails.result.role == 'Admin' || userDetails.result.role == 'Company')) {
                            return <NavItem key={menu.id} item={menu} level={1} />;
                        }
                        if (
                            menu.id == 'add-announcement' &&
                            (userDetails.result.role == 'Admin' ||
                                userDetails.result.role == 'Manager' ||
                                userDetails.result.role == 'Company')
                        ) {
                            return <NavItem key={menu.id} item={menu} level={1} />;
                        }

                        if (
                            menu.id == 'leads' &&
                            (userDetails.result.role == 'Admin' ||
                                userDetails.result.role == 'Manager' ||
                                userDetails.result.role == 'Company')
                        ) {
                            return <NavItem key={menu.id} item={menu} level={1} />;
                        }

                        if (
                            menu.id == 'stages' &&
                            (userDetails.result.role == 'Admin' ||
                                userDetails.result.role == 'Manager' ||
                                userDetails.result.role == 'Company')
                        ) {
                            return <NavItem key={menu.id} item={menu} level={1} />;
                        }

                        if (
                            menu.id == 'all-stages' &&
                            (userDetails.result.role == 'Admin' ||
                                userDetails.result.role == 'Manager' ||
                                userDetails.result.role == 'Company')
                        ) {
                            return <NavItem key={menu.id} item={menu} level={1} />;
                        }

                        if (
                            menu.id == 'payments' &&
                            (userDetails.result.role == 'Admin' ||
                                userDetails.result.role == 'Manager' ||
                                userDetails.result.role == 'Company')
                        ) {
                            return <NavItem key={menu.id} item={menu} level={1} />;
                        }

                        if (
                            menu.id == 'chat-groups' &&
                            (userDetails.result.role == 'Admin' ||
                                userDetails.result.role == 'Manager' ||
                                userDetails.result.role == 'Company' ||
                                userDetails.result.role == 'Employee')
                        ) {
                            return <NavItem key={menu.id} item={menu} level={1} />;
                        }

                        // ** Super Admin ** //

                        if (menu.id == 'super-admin-dashboard' && userDetails.result.role == 'Super Admin') {
                            return <NavItem key={menu.id} item={menu} level={1} />;
                        }

                        if (menu.id == 'super-admin-users-list' && userDetails.result.role == 'Super Admin') {
                            return <NavItem key={menu.id} item={menu} level={1} />;
                        }

                        if (menu.id == 'super-admin-assign-users' && userDetails.result.role == 'Super Admin') {
                            return <NavItem key={menu.id} item={menu} level={1} />;
                        }
                        if (menu.id == 'super-admin-add-announcement' && userDetails.result.role == 'Super Admin') {
                            return <NavItem key={menu.id} item={menu} level={1} />;
                        }
                        if (menu.id == 'super-admin-owner-view' && userDetails.result.role == 'Super Admin') {
                            return <NavItem key={menu.id} item={menu} level={1} />;
                        }

                        if (menu.id == 'send-invoice' && userDetails.result.role == 'Super Admin') {
                            return <NavItem key={menu.id} item={menu} level={1} />;
                        }

                        // if (menu.id == 'super-admin-stages' && userDetails.result.role == 'Super Admin') {
                        //     return <NavItem key={menu.id} item={menu} level={1} />;
                        // }
                        // if (menu.id == 'super-admin-leads' && userDetails.result.role == 'Super Admin') {
                        //     return <NavItem key={menu.id} item={menu} level={1} />;
                        // }
                    }
                })}
            </List>

            {/* group divider */}
            <Divider sx={{ mt: 0.25, mb: 1.25 }} />
        </>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;
