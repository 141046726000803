import jwt_decode from 'jwt-decode';
import PropTypes from 'prop-types';
import { GetData, PostData, PostFormData } from '../../services';
import CompanyContext from './CompanyContext';

const CompanyState = (props) => {
    const getAllUsers = async (data) => {
        const res = await PostData(true, '/super-admin/all-company', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const addCompany = async (data) => {
        const res = await PostData(true, '/super-admin', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getSingleCompany = async ({ id }) => {
        const res = await GetData(true, '/super-admin/company/' + id);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const updateCompany = async (data) => {
        const res = await PostData(true, '/super-admin/update-company', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const Delete = async (data) => {
        const res = await PostData(true, '/super-admin/delete', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const DeleteUser = async (data) => {
        const res = await PostData(true, '/super-admin/delete-user', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const addMoreUser = async (data) => {
        const res = await PostFormData(true, '/super-admin/add-user', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getAllCompany = async () => {
        const res = await PostData(true, '/super-admin/get-all-company');
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getCompanyAllUser = async (data) => {
        const res = await PostData(true, '/super-admin/all-user', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getAllReports = async (data) => {
        const res = await PostData(true, '/super-admin/get-all-reports', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getAllManager = async (data) => {
        const res = await PostData(true, '/super-admin/all-manager', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const assignUser = async (data, id, company_id) => {
        const res = await PostData(true, '/super-admin/assign-users', { data, id, company_id });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const announcementsDetails = async (data) => {
        const res = await PostData(true, '/super-admin/get-announcement', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getOrders = async (data) => {
        const { token } = JSON.parse(localStorage.getItem('Techryt'));
        const { id } = jwt_decode(token.access_token);
        const res = await PostData(true, '/super-admin/get-my-reports', { id: id, data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const DeleteReport = async (data) => {
        const res = await PostData(true, '/orders/delete', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const addAnnouncement = async (data) => {
        const res = await PostData(true, '/super-admin/add-announcement', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getAllLeads = async (data) => {
        const res = await PostData(true, '/super-admin/get-all-leads', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getAllStages = async (data) => {
        const res = await PostData(true, '/super-admin/get-all-stages', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const sendInvoice = async (data) => {
        const res = await PostData(true, '/super-admin/send-invoice', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getAllinvoice = async (data) => {
        const res = await PostData(true, '/super-admin/get-all-invoice', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    return (
        <CompanyContext.Provider
            value={{
                getAllUsers,
                addCompany,
                getSingleCompany,
                updateCompany,
                Delete,
                addMoreUser,
                getAllCompany,
                getCompanyAllUser,
                DeleteUser,
                getAllReports,
                getAllManager,
                assignUser,
                announcementsDetails,
                getOrders,
                DeleteReport,
                addAnnouncement,
                getAllLeads,
                getAllStages,
                sendInvoice,
                getAllinvoice
            }}
        >
            {props.children}
        </CompanyContext.Provider>
    );
};

CompanyState.propTypes = {
    children: PropTypes.node
};

export default CompanyState;
