import { useEffect, useContext } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

// project imports
import Customization from '../Customization';

import AuthContext from '../../context/auth/AuthContext';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
    const navigate = useNavigate();
    const context = useContext(AuthContext);
    const { user } = context;

    useEffect(() => {
        if (user !== null) {
            navigate('/dashboard');
        }
    });

    return (
        <>
            <Outlet />
            <Customization />
        </>
    );
};

export default MinimalLayout;
