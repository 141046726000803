import { lazy } from 'react';

// project imports
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
const stripePromise2 = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY2);

// pages routing
const Dashboard = Loadable(lazy(() => import('views/pages/dashboard')));
const MainDashboard = Loadable(lazy(() => import('views/pages/dashboard/main-dashboard')));
const AddReport = Loadable(lazy(() => import('views/pages/add-report')));
const EditReport = Loadable(lazy(() => import('views/pages/add-report/edit-report')));
const ReportList = Loadable(lazy(() => import('views/pages/reports')));
const MainReportList = Loadable(lazy(() => import('views/pages/reports/main')));
const Profile = Loadable(lazy(() => import('views/pages/profile')));
const Users = Loadable(lazy(() => import('views/pages/users')));
const ManagerUsers = Loadable(lazy(() => import('views/pages/users/manager-user-list')));
const AddUser = Loadable(lazy(() => import('views/pages/add-user')));
const EditUser = Loadable(lazy(() => import('views/pages/add-user/edit-user')));
const AddAnnouncement = Loadable(lazy(() => import('views/pages/announcement')));
const ManagerList = Loadable(lazy(() => import('views/pages/assign-users/manager-list')));
const CheckInList = Loadable(lazy(() => import('views/pages/check-in-list')));
const UserCheckInList = Loadable(lazy(() => import('views/pages/check-in-list/users-check-in-list')));
const AllUserCheckInList = Loadable(lazy(() => import('views/pages/check-in-list/all-check-in-list')));
const EditCheckIn = Loadable(lazy(() => import('views/pages/check-in-list/edit-check-in')));

const AddLeads = Loadable(lazy(() => import('views/pages/add-leads')));
const Leads = Loadable(lazy(() => import('views/pages/leads')));
const AddStages = Loadable(lazy(() => import('views/pages/add-stages')));
const Stages = Loadable(lazy(() => import('views/pages/stages')));

const AllStages = Loadable(lazy(() => import('views/pages/stages/allStage')));

const ChatGroups = Loadable(lazy(() => import('views/pages/chat-groups')));
const AddGroups = Loadable(lazy(() => import('views/pages/add-group')));
const Chat = Loadable(lazy(() => import('views/pages/chat')));

// ** Super Admin ** //
const SuperAdminUsers = Loadable(lazy(() => import('views/pages/users/super-admin-user-list')));
const Company = Loadable(lazy(() => import('views/pages/super-admin-company')));
const AddCompany = Loadable(lazy(() => import('views/pages/super-admin-company/addCompany')));
const EditCompany = Loadable(lazy(() => import('views/pages/super-admin-company/editCompany')));
const SuperAdminAddUser = Loadable(lazy(() => import('views/pages/super-admin-add-user')));
const SuperAdminCompanyReport = Loadable(lazy(() => import('views/pages/reports/super-admin-company-report')));
const SuperAdminAddReport = Loadable(lazy(() => import('views/pages/super-admin-add-report')));
const SuperAdminManagerList = Loadable(lazy(() => import('views/pages/assign-users/super-admin-manager-list')));
const SuperAdminDashboard = Loadable(lazy(() => import('views/pages/dashboard/super-admin-dashboard')));
const SuperAdminAddAnnouncement = Loadable(lazy(() => import('views/pages/announcement/super-admin-announcement')));

const AddCompanyLeads = Loadable(lazy(() => import('views/pages/add-leads/add-company-lead')));
const EditLeads = Loadable(lazy(() => import('views/pages/add-leads/edit-lead')));
const SuperAdminLeads = Loadable(lazy(() => import('views/pages/leads/super-admin-leads')));
const AddCompanyStages = Loadable(lazy(() => import('views/pages/add-stages/add-company-stages')));
const SuperAdminStages = Loadable(lazy(() => import('views/pages/stages/super-admin-stages')));
const EditCompanyStages = Loadable(lazy(() => import('views/pages/add-stages/edit-stage')));

const SendPaymentRequest = Loadable(lazy(() => import('views/pages/payment-request')));
const Payments = Loadable(lazy(() => import('views/pages/payment-request/payments')));
const PaymentPay = Loadable(lazy(() => import('views/pages/payment-request/pay')));

import AuthState from 'context/auth/AuthState';
import LeadsState from '../context/leads/LeadState';
import OrdersState from '../context/orders/OrderState';
import PaymentsState from '../context/payments/PaymentsState';
import StagesState from '../context/stages/StageState';
import CompanyState from '../context/super-admin-company/CompanyState';

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    element: <MainLayout />,
    children: [
        {
            path: '/dashboard',
            element: (
                <OrdersState>
                    <Dashboard />
                </OrdersState>
            )
        },
        {
            path: '/main-dashboard',
            element: (
                <OrdersState>
                    <MainDashboard />
                </OrdersState>
            )
        },
        {
            path: '/super-admin-dashboard',
            element: (
                <CompanyState>
                    <SuperAdminDashboard />
                </CompanyState>
            )
        },
        {
            path: 'add-report',
            element: (
                <OrdersState>
                    <AddReport />
                </OrdersState>
            )
        },
        {
            path: 'owner-view',
            element: (
                <OrdersState>
                    <ReportList />
                </OrdersState>
            )
        },
        {
            path: 'main-owner-view',
            element: (
                <OrdersState>
                    <MainReportList />
                </OrdersState>
            )
        },
        {
            path: 'users-list',
            element: <Users />
        },
        {
            path: 'manager-users-list',
            element: <ManagerUsers />
        },
        {
            path: 'super-admin-users-list',
            element: (
                <CompanyState>
                    <SuperAdminUsers />
                </CompanyState>
            )
        },
        {
            path: 'add-user',
            element: <AddUser />
        },
        {
            path: 'add-company-user',
            element: (
                <CompanyState>
                    <SuperAdminAddUser />
                </CompanyState>
            )
        },
        {
            path: 'super-admin-owner-view',
            element: (
                <CompanyState>
                    <SuperAdminCompanyReport />
                </CompanyState>
            )
        },
        {
            path: 'add-company-report',
            element: (
                <OrdersState>
                    <CompanyState>
                        <SuperAdminAddReport />
                    </CompanyState>
                </OrdersState>
            )
        },
        {
            path: 'check-in',
            element: <CheckInList />
        },
        {
            path: 'user-check-in-list',
            element: <UserCheckInList />
        },
        {
            path: 'all-check-in-list',
            element: <AllUserCheckInList />
        },
        {
            path: 'user-check-in/:id',
            element: <EditCheckIn />
        },
        {
            path: 'assign-users',
            element: (
                <CompanyState>
                    <ManagerList />
                </CompanyState>
            )
        },
        {
            path: 'super-admin-assign-users',
            element: (
                <CompanyState>
                    <SuperAdminManagerList />
                </CompanyState>
            )
        },
        {
            path: 'add-announcement',
            element: <AddAnnouncement />
        },
        {
            path: 'super-admin-add-announcement',
            element: (
                <CompanyState>
                    <SuperAdminAddAnnouncement />
                </CompanyState>
            )
        },
        {
            path: 'user/:id',
            element: (
                <OrdersState>
                    <EditUser />
                </OrdersState>
            )
        },
        {
            path: 'report/:id',
            element: (
                <OrdersState>
                    <EditReport />
                </OrdersState>
            )
        },
        {
            path: 'account',
            element: (
                <OrdersState>
                    <Profile />
                </OrdersState>
            )
        },
        {
            path: '/companies',
            element: (
                <CompanyState>
                    <Company />
                </CompanyState>
            )
        },
        {
            path: '/add-company',
            element: (
                <CompanyState>
                    <AddCompany />
                </CompanyState>
            )
        },
        {
            path: '/edit-company/:id',
            element: (
                <CompanyState>
                    <EditCompany />
                </CompanyState>
            )
        },
        {
            path: '/super-admin-leads',
            element: (
                <LeadsState>
                    <CompanyState>
                        <SuperAdminLeads />
                    </CompanyState>
                </LeadsState>
            )
        },
        {
            path: '/super-admin-stages',
            element: (
                <StagesState>
                    <CompanyState>
                        <SuperAdminStages />
                    </CompanyState>
                </StagesState>
            )
        },
        {
            path: '/stages',
            element: (
                <StagesState>
                    <Stages />
                </StagesState>
            )
        },
        {
            path: '/all-stages',
            element: (
                <StagesState>
                    <LeadsState>
                        <AllStages />
                    </LeadsState>
                </StagesState>
            )
        },
        {
            path: '/add-lead',
            element: (
                <LeadsState>
                    <CompanyState>
                        <AddLeads />
                    </CompanyState>
                </LeadsState>
            )
        },
        {
            path: '/leads',
            element: (
                <LeadsState>
                    <CompanyState>
                        <Leads />
                    </CompanyState>
                </LeadsState>
            )
        },
        {
            path: '/add-company-lead',
            element: (
                <LeadsState>
                    <CompanyState>
                        <AddCompanyLeads />
                    </CompanyState>
                </LeadsState>
            )
        },
        {
            path: '/edit-lead/:id',
            element: (
                <LeadsState>
                    <CompanyState>
                        <EditLeads />
                    </CompanyState>
                </LeadsState>
            )
        },
        {
            path: '/add-stage',
            element: (
                <StagesState>
                    <CompanyState>
                        <AddStages />
                    </CompanyState>
                </StagesState>
            )
        },
        {
            path: '/add-company-stage',
            element: (
                <StagesState>
                    <CompanyState>
                        <AddCompanyStages />
                    </CompanyState>
                </StagesState>
            )
        },
        {
            path: '/edit-stage/:id',
            element: (
                <StagesState>
                    <CompanyState>
                        <EditCompanyStages />
                    </CompanyState>
                </StagesState>
            )
        },
        {
            path: '/send-invoice',
            element: (
                <StagesState>
                    <CompanyState>
                        <SendPaymentRequest />
                    </CompanyState>
                </StagesState>
            )
        },
        {
            path: '/payments',
            element: (
                <Elements stripe={stripePromise2}>
                    <PaymentsState>
                        <Payments />
                    </PaymentsState>
                </Elements>
            )
        },
        {
            path: '/chat-groups',
            element: (
                // <CompanyState>
                <ChatGroups />
                // </CompanyState>
            )
        },
        {
            path: '/add-group',
            element: (
                <AuthState>
                    <CompanyState>
                        <AddGroups />
                    </CompanyState>
                </AuthState>
            )
        },
        {
            path: '/chat/:chatId',
            element: (
                // <CompanyState>
                <Chat />
                // </CompanyState>
            )
        },
        {
            path: 'pay/:confirmCode',
            element: (
                <Elements stripe={stripePromise2}>
                    <PaymentsState>
                        <PaymentPay />
                    </PaymentsState>
                </Elements>
            )
        }
    ]
};

export default MainRoutes;
