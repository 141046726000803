import jwt_decode from 'jwt-decode';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetData, PostData, PostFormData, PutData, PutFormData } from '../../services';
import AuthContext from './AuthContext';
import { useLocalStorage } from './useLocalStorage';

const AuthState = (props) => {
    const [user, setUser] = useLocalStorage('Techryt', null);
    const [userDetails, setUserDetails] = useState([]);
    const [checkInUser, setCheckInUser] = useState({});
    // const tokenTime = user ? user.token.accessTokenExpiresIn : '';
    // const [tokenExpirytime, setTokenExpirytime] = useState(tokenTime);

    const navigate = useNavigate();
    const addUser = async (data) => {
        const res = await PostData(false, '/users', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const updateUser = async (data, id) => {
        const res = await PutFormData(true, '/users/' + id, data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const updatePassword = async (data) => {
        const res = await PutData(true, '/users/change-password/' + data.id, data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const confirmUser = async ({ id, status }) => {
        const res = await PutData(false, '/users/updateStatus/' + id, { status });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const loginUser = async (data) => {
        const res = await PostData(false, '/users/login', data);
        if (res.status === 200) {
            setUser(res.payload.result);
            return res.payload;
        }
    };

    const logout = async () => {
        localStorage.removeItem('Techryt');
        navigate('/', { replace: true });
        setUser(null);
    };

    const getUserDetails = async ({ id }) => {
        const res = await GetData(true, '/users/' + id);
        if (res.status === 200) {
            setUserDetails(res.payload);
            if (res.payload.result.blockStatus == '0') {
                localStorage.removeItem('Techryt');
                window.location.href = '/';
            } else {
                return res.payload;
            }
        }
    };

    const checkInDetails = async (data) => {
        const res = await PostData(true, '/users/check-in-details', data);
        if (res.status === 200) {
            setCheckInUser(res.payload.result ? res.payload.result : {});
        }
    };

    const refreshToken = async (data) => {
        const res = await PostData(true, '/users/refresh-token', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const forgotPassword = async (data) => {
        const res = await PostData(false, '/users/forgot-password', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const verifyResetPasswordLink = async (id, token) => {
        const res = await GetData(false, '/users/reset-password/' + id + '/' + token);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const resetPassword = async (id, token, data) => {
        const res = await PostData(false, '/users/reset-password/' + id + '/' + token, data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getDashboardDetails = async (id) => {
        const res = await PostData(false, '/users/get-dashboard-details', { id });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getSingleUser = async ({ id }) => {
        const res = await GetData(true, '/users/' + id);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getAllAdmins = async () => {
        const res = await GetData(true, '/users/admins');
        if (res.status === 200) {
            return res.payload;
        }
    };

    useEffect(() => {
        (async () => {
            if (user) {
                const token = user ? user.token.access_token : '';
                const { id } = jwt_decode(token);
                await getUserDetails({ id });
                await checkInDetails({ id });
            }
        })();
    }, [user]);

    const getAllUser = async (data) => {
        const res = await PostData(true, '/users/all-user', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getAllManagerUser = async (data) => {
        const res = await PostData(true, '/users/get-all-manager-users', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const addMoreUser = async (data) => {
        const res = await PostFormData(true, '/users/add-user', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const addAnnouncement = async (data) => {
        const res = await PostData(true, '/users/add-announcement', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const announcementsDetails = async (data) => {
        const res = await PostData(true, '/users/get-announcement', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const updateSingleUser = async (data) => {
        const res = await PostFormData(true, '/users/update-single-user', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getAllAnnouncements = async (data) => {
        const res = await PostData(true, '/users/all-announcement', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const Delete = async (data) => {
        const res = await PostData(true, '/users/delete', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const DeleteAnnouncements = async (data) => {
        const res = await PostData(true, '/users/delete-announcement', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getAllEmployees = async (data) => {
        const res = await PostData(true, '/users/all-employees', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getAllAssignEmployees = async (data) => {
        const res = await PostData(true, '/users/all-assign-employees', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getAllUsersByCompany = async (data) => {
        const res = await PostData(true, '/users/all-all-user-by-company', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getAllManager = async (data) => {
        const res = await PostData(true, '/users/all-manager', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const assignUser = async (data) => {
        const res = await PostData(true, '/users/assign-users', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const checkIn = async (data) => {
        const res = await PostData(true, '/users/check-in', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const checkInList = async (data) => {
        const { token } = JSON.parse(localStorage.getItem('Techryt'));
        const { id } = jwt_decode(token.access_token);
        const res = await PostData(true, '/users/check-in-list', { id: id, data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const userCheckInList = async (data) => {
        const res = await PostData(true, '/users/user-check-in-list', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const allUserCheckInList = async (data) => {
        const res = await PostData(true, '/users/all-check-in-list', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const updateCheckIn = async (data) => {
        const res = await PostData(true, '/users/update-check-in', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const DeleteCheckIn = async (data) => {
        const res = await PostData(true, '/users/delete-check-in', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getSingleCheckIn = async (data) => {
        const res = await PostData(true, '/users/get-check-in', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const updateCheckInForm = async (data) => {
        const res = await PostData(true, '/users/update-check-in-form', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const stripeCreateCoachPayment = async (data) => {
        const res = await PostData(true, '/stripe/create-stripe-booking-payment', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const insertStripeBookingData = async (data) => {
        const res = await PostData(true, '/stripe/add-booking-data', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    return (
        <AuthContext.Provider
            value={{
                userDetails,
                user,
                addUser,
                confirmUser,
                loginUser,
                logout,
                refreshToken,
                updateUser,
                getUserDetails,
                updatePassword,
                forgotPassword,
                verifyResetPasswordLink,
                resetPassword,
                getDashboardDetails,
                getAllUser,
                addMoreUser,
                getSingleUser,
                getAllAdmins,
                updateSingleUser,
                addAnnouncement,
                announcementsDetails,
                Delete,
                getAllAnnouncements,
                DeleteAnnouncements,
                getAllEmployees,
                getAllAssignEmployees,
                getAllUsersByCompany,
                getAllManager,
                getAllManagerUser,
                assignUser,
                checkIn,
                checkInDetails,
                checkInUser,
                checkInList,
                userCheckInList,
                updateCheckIn,
                DeleteCheckIn,
                getSingleCheckIn,
                updateCheckInForm,
                allUserCheckInList,
                stripeCreateCoachPayment,
                insertStripeBookingData
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};

AuthState.propTypes = {
    children: PropTypes.node
};

export default AuthState;
