import { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import moment from 'moment-timezone';
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Chip,
    ClickAwayListener,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Stack,
    Typography
} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import { blueGrey } from '@mui/material/colors';
import { IconLogout, IconSettings } from '@tabler/icons';
import { MENU_OPEN } from 'store/actions';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import LogoutIcon from '@mui/icons-material/Logout';
import AuthContext from '../../../../context/auth/AuthContext';

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: blueGrey[500],
            color: '#fff'
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
    };
}

const ProfileSection = () => {
    const context = useContext(AuthContext);
    const { userDetails, logout, checkIn, checkInUser, checkInDetails } = context;
    let userAttendance;
    let lastAttendence;
    if (checkInUser.attendence) {

        userAttendance = JSON.parse(checkInUser.attendence)
        lastAttendence = userAttendance[userAttendance.length - 1]
    }

    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const navigate = useNavigate();
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [open, setOpen] = useState(false);

    const dispatch = useDispatch();
    const anchorRef = useRef(null);

    const handleLogout = async () => {
        await logout();
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListItemClick = (event, index, route = '') => {
        dispatch({ type: MENU_OPEN, id: '' });
        setSelectedIndex(index);
        handleClose(event);
        if (route && route !== '') {
            navigate(route);
        }
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const handleCheckIn = async (type, company_id, userId) => {
        let data = {
            type: type,
            company_id: company_id,
            userId: userId
        };
        await checkIn(data);
        await checkInDetails({ id: userId });
    };

    let lunchBreak = '';

    if (Object.keys(checkInUser).length > 0) {
        if (checkInUser.lunch_break_end == null && checkInUser.lunch_break_start == null && userDetails.result.role == 'Employee') {
            lunchBreak = (
                <Button
                    onClick={() => handleCheckIn('lunchbreakstart', userDetails.result.company_id, userDetails.result.id)}
                    sx={{ mr: 2 }}
                    size="small"
                    variant="contained"
                    startIcon={<RestaurantIcon />}
                >
                    lunch break
                </Button>
            );
        }
    }

    let lunchBreakClose = '';
    if (Object.keys(checkInUser).length > 0 && userDetails.result.role == 'Employee') {
        if (checkInUser.lunch_break_start != null && checkInUser.lunch_break_end == null) {
            lunchBreakClose = (
                <Button
                    onClick={() => handleCheckIn('lunchbreakclose', userDetails.result.company_id, userDetails.result.id)}
                    sx={{ mr: 2 }}
                    size="small"
                    variant="contained"
                    startIcon={<RestaurantIcon />}
                >
                    Close lunch break
                </Button>
            );
        }
    }

    let checkout = '';
    if (Object.keys(checkInUser).length > 0 && userDetails.result.role == 'Employee') {
        if (lastAttendence.endTime == null) {
            checkout = (
                <Button
                    onClick={() => handleCheckIn('checkout', userDetails.result.company_id, userDetails.result.id)}
                    sx={{ mr: 2 }}
                    size="small"
                    variant="contained"
                    color="error"
                    startIcon={<LogoutIcon />}
                >
                    Check Out
                </Button>
            );
        } else {
            checkout = (
                <Button
                    onClick={() => handleCheckIn('checkin', userDetails.result.company_id, userDetails.result.id)}
                    sx={{ mr: 2 }}
                    size="small"
                    variant="contained"
                    color="error"
                    startIcon={<LogoutIcon />}
                >
                    Check In
                </Button>
            );
        }
    }

    return (
        <>
            {Object.keys(userDetails).length == 0 ? (
                ''
            ) : (
                <div>
                    {Object.keys(checkInUser).length == 0 && userDetails.result.role == 'Employee' ? (
                        <Button
                            onClick={() => handleCheckIn('checkin', userDetails.result.company_id, userDetails.result.id)}
                            color="success"
                            sx={{ mr: 2 }}
                            size="small"
                            variant="contained"
                            startIcon={<AssignmentTurnedInIcon />}
                        >
                            Check In
                        </Button>
                    ) : userDetails.result.role == 'Employee' ? (
                        <Chip
                            label={'Check in - ' + moment(checkInUser.check_in).format('lll')}
                            sx={{ mr: 2 }}
                            color="primary"
                            variant="outlined"
                        />
                    ) : (
                        ''
                    )}
                    {lunchBreak}
                    {lunchBreakClose}
                    {checkout}

                    <Chip
                        sx={{
                            height: '48px',
                            alignItems: 'center',
                            borderRadius: '27px',
                            transition: 'all .2s ease-in-out',
                            borderColor: theme.palette.primary.light,
                            backgroundColor: theme.palette.primary.light,
                            '&[aria-controls="menu-list-grow"], &:hover': {
                                borderColor: theme.palette.primary.main,
                                background: `${theme.palette.primary.main}!important`,
                                color: theme.palette.primary.light,
                                '& svg': {
                                    stroke: theme.palette.primary.light
                                }
                            },
                            '& .MuiChip-label': {
                                lineHeight: 0
                            }
                        }}
                        icon={
                            typeof userDetails.result.profileImage != '' && userDetails.result.lastName != '' ? (
                                <Avatar
                                    {...stringAvatar(userDetails.result.firstName + ' ' + userDetails.result.lastName)}
                                    src={
                                        userDetails.result.profileImage
                                            ? process.env.REACT_APP_WEB_ROOT_IMAGE_PATH + '/uploads/' + userDetails.result.profileImage
                                            : ''
                                    }
                                />
                            ) : (
                                <Avatar />
                            )
                        }
                        label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
                        variant="outlined"
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        color="secondary"
                    />
                    <Popper
                        placement="bottom-end"
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                        popperOptions={{
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, 14]
                                    }
                                }
                            ]
                        }}
                    >
                        {({ TransitionProps }) => (
                            <Transitions in={open} {...TransitionProps}>
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                            <Box sx={{ p: 2 }}>
                                                <Stack>
                                                    <Stack direction="row" spacing={0.5} alignItems="center">
                                                        <Typography variant="h4">Welcome Back,</Typography>
                                                        <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                                                            {userDetails.result.firstName}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            </Box>
                                            <Divider />
                                            <Box sx={{ pl: 2, pr: 2 }}>
                                                <List
                                                    component="nav"
                                                    sx={{
                                                        width: '100%',
                                                        maxWidth: 350,
                                                        minWidth: 300,
                                                        backgroundColor: theme.palette.background.paper,
                                                        borderRadius: '10px',
                                                        [theme.breakpoints.down('md')]: {
                                                            minWidth: '100%'
                                                        },
                                                        '& .MuiListItemButton-root': {
                                                            mt: 0.5
                                                        }
                                                    }}
                                                >
                                                    <ListItemButton
                                                        sx={{
                                                            borderRadius: `${customization.borderRadius}px`
                                                        }}
                                                        onClick={(event) => handleListItemClick(event, 0, '/account')}
                                                    >
                                                        <ListItemIcon>
                                                            <IconSettings stroke={1.5} size="1.3rem" />
                                                        </ListItemIcon>
                                                        <ListItemText primary={<Typography variant="body2">Account Settings</Typography>} />
                                                    </ListItemButton>
                                                    <ListItemButton
                                                        sx={{
                                                            borderRadius: `${customization.borderRadius}px`
                                                        }}
                                                        selected={selectedIndex === 4}
                                                        onClick={handleLogout}
                                                    >
                                                        <ListItemIcon>
                                                            <IconLogout stroke={1.5} size="1.3rem" />
                                                        </ListItemIcon>
                                                        <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                                                    </ListItemButton>
                                                </List>
                                            </Box>
                                        </MainCard>
                                    </ClickAwayListener>
                                </Paper>
                            </Transitions>
                        )}
                    </Popper>
                </div>
            )}
        </>
    );
};

export default ProfileSection;
