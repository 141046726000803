import PropTypes from 'prop-types';
import { useEffect } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase } from '@mui/material';

// project imports
import ProfileSection from './ProfileSection';
// import NotificationSection from './NotificationSection';
import CartSection from './CartSection';

// assets
import { IconMenu2 } from '@tabler/icons';
import { Link } from 'react-router-dom';

// project imports
import config from 'config';
import logo1 from 'assets/images/logo.png';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();

    let pageTitle = document.location.pathname;
    let Title = '';
    switch (pageTitle) {
        case '/add-report':
            Title = `Add Report | TechRyt Portal`;
            break;
        case '/owner-view':
            Title = `Owner View | TechRyt Portal`;
            break;
        default:
            Title = `TechRyt Portal`;
            break;
    }

    useEffect(() => {
        document.title = Title;
    }, [Title]);

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <ButtonBase disableRipple component={Link} to={config.defaultPath}>
                        <img src={logo1} alt="Techryt" style={{ borderRadius: '5px' }} width="195px" />
                    </ButtonBase>
                </Box>

                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden', ml: 2 }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.dark,
                            color: theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>

            <Box sx={{ flexGrow: 2 }} />

            {/* <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <ButtonBase disableRipple component={Link} to="/">
                    <img src={logo1} alt="Techryt" style={{ borderRadius: '5px' }} width="220px" />
                </ButtonBase>
            </Box> */}

            {/* header search */}
            {/* <SearchSection /> */}

            <Box sx={{ flexGrow: 1 }} />

            {/* notification & profile */}
            {/* <NotificationSection /> */}
            <CartSection />
            <ProfileSection />
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
