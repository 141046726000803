import PropTypes from 'prop-types';
import OrderContext from './LeadContext';
import { GetData, PostData, PutData } from '../../services';
import jwt_decode from 'jwt-decode';

const LeadState = (props) => {
    const addLead = async (data) => {
        const res = await PostData(true, '/leads', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getLeads = async (data) => {
        const { token } = JSON.parse(localStorage.getItem('Techryt'));
        const { company_id } = jwt_decode(token.access_token);
        const res = await PostData(true, '/leads/lead-list', { company_id: company_id, data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const updateLead = async (data) => {
        const res = await PutData(true, '/leads/' + data.id, data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getSingleLeadDetails = async ({ id }) => {
        const res = await GetData(true, '/leads/' + id);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const countLeads = async (data) => {
        const res = await PostData(true, '/leads/order-count', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const DeleteLead = async (data) => {
        const res = await PostData(true, '/leads/delete', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getStageList = async (data) => {
        const res = await PostData(true, '/leads/get-stage-list', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    return (
        <OrderContext.Provider value={{ addLead, getLeads, updateLead, getSingleLeadDetails, countLeads, DeleteLead, getStageList }}>
            {props.children}
        </OrderContext.Provider>
    );
};

LeadState.propTypes = {
    children: PropTypes.node
};

export default LeadState;
