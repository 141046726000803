import PropTypes from 'prop-types';
import OrderContext from './StageContext';
import { GetData, PostData, PutData } from '../../services';
import jwt_decode from 'jwt-decode';

const StageState = (props) => {
    const addStage = async (data) => {
        const res = await PostData(true, '/stages', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const allStages = async () => {
        const { token } = JSON.parse(localStorage.getItem('Techryt'));
        const { company_id } = jwt_decode(token.access_token);
        const res = await PostData(true, '/stages/all-stage', { company_id: company_id });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getStages = async (data) => {
        const { token } = JSON.parse(localStorage.getItem('Techryt'));
        const { company_id } = jwt_decode(token.access_token);
        const res = await PostData(true, '/stages/stage-list', { company_id: company_id, data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const updateStage = async (data) => {
        const res = await PutData(true, '/stages/' + data.id, data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getSingleStageDetails = async ({ id }) => {
        const res = await GetData(true, '/stages/' + id);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const countStage = async (data) => {
        const res = await PostData(true, '/stages/order-count', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const DeleteReport = async (data) => {
        const res = await PostData(true, '/stages/delete', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const updateStagePosition = async (data) => {
        const res = await PostData(true, '/stages/update-position', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const updateLeadPosition = async (data) => {
        const res = await PostData(true, '/stages/update-lead-position', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const updateLeadRowPosition = async (data) => {
        const res = await PostData(true, '/stages/update-lead-row-position', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    return (
        <OrderContext.Provider
            value={{
                addStage,
                getStages,
                updateStage,
                getSingleStageDetails,
                countStage,
                DeleteReport,
                allStages,
                updateStagePosition,
                updateLeadPosition,
                updateLeadRowPosition
            }}
        >
            {props.children}
        </OrderContext.Provider>
    );
};

StageState.propTypes = {
    children: PropTypes.node
};

export default StageState;
