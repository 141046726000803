import PropTypes from 'prop-types';
import PaymentsContext from './PaymentsContext';
import { GetData, PostData, PutData, PostFormData } from '../../services';
import jwt_decode from 'jwt-decode';

const PaymentsState = (props) => {
    const sendInvoice = async (data) => {
        const res = await PostData(true, '/super-admin/send-invoice', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getAllinvoice = async (data) => {
        const res = await PostData(true, '/super-admin/get-all-invoice', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getSingleinvoice = async (data) => {
        const res = await PostData(true, '/super-admin/get-single-invoice', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    return (
        <PaymentsContext.Provider
            value={{
                sendInvoice,
                getAllinvoice,
                getSingleinvoice
            }}
        >
            {props.children}
        </PaymentsContext.Provider>
    );
};

PaymentsState.propTypes = {
    children: PropTypes.node
};

export default PaymentsState;
