// assets
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import BusinessIcon from '@mui/icons-material/Business';
import CampaignIcon from '@mui/icons-material/Campaign';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HistoryIcon from '@mui/icons-material/History';
import PaymentsIcon from '@mui/icons-material/Payments';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { IconDashboard, IconShoppingCartPlus } from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconShoppingCartPlus,
    HistoryIcon,
    AccountBalanceWalletOutlinedIcon,
    ConfirmationNumberOutlinedIcon,
    HelpOutlineIcon,
    EmojiEventsOutlinedIcon,
    FormatListBulletedIcon,
    PeopleOutlinedIcon,
    CampaignIcon,
    BusinessIcon,
    AssignmentIndIcon,
    AssignmentTurnedInIcon,
    AssignmentIcon,
    AutoGraphIcon,
    ReceiptIcon,
    PaymentsIcon
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Home',
            type: 'item',
            url: '/dashboard',
            icon: icons.EmojiEventsOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'super-admin-dashboard',
            title: 'Home',
            type: 'item',
            url: '/super-admin-dashboard',
            icon: icons.EmojiEventsOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'companies',
            title: 'Companies',
            type: 'item',
            url: '/companies',
            icon: icons.BusinessIcon,
            breadcrumbs: false
        },
        {
            id: 'add-company',
            title: 'Add Company',
            type: 'item',
            url: '/add-company',
            icon: icons.BusinessIcon,
            breadcrumbs: false
        },
        {
            id: 'main-dashboard',
            title: 'Leaderboard',
            type: 'item',
            url: '/main-dashboard',
            icon: icons.EmojiEventsOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'owner-view',
            title: 'My reports',
            type: 'item',
            url: '/owner-view',
            icon: icons.FormatListBulletedIcon,
            breadcrumbs: false
        },
        {
            id: 'check-in',
            title: 'Check in List',
            type: 'item',
            url: '/check-in',
            icon: icons.AssignmentTurnedInIcon,
            breadcrumbs: false
        },
        {
            id: 'main-owner-view',
            title: 'All Reports',
            type: 'item',
            url: '/main-owner-view',
            icon: icons.FormatListBulletedIcon,
            breadcrumbs: false
        },
        {
            id: 'super-admin-owner-view',
            title: 'All Reports',
            type: 'item',
            url: '/super-admin-owner-view',
            icon: icons.FormatListBulletedIcon,
            breadcrumbs: false
        },
        {
            id: 'super-admin-users-list',
            title: 'Users',
            type: 'item',
            url: '/super-admin-users-list',
            icon: icons.PeopleOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'manager-users-list',
            title: 'Users',
            type: 'item',
            url: '/manager-users-list',
            icon: icons.PeopleOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'user-check-in-list',
            title: 'User Check in List',
            type: 'item',
            url: '/user-check-in-list',
            icon: icons.AssignmentTurnedInIcon,
            breadcrumbs: false
        },
        {
            id: 'all-check-in-list',
            title: 'All Check in List',
            type: 'item',
            url: '/all-check-in-list',
            icon: icons.AssignmentTurnedInIcon,
            breadcrumbs: false
        },
        {
            id: 'users-list',
            title: 'Users',
            type: 'item',
            url: '/users-list',
            icon: icons.PeopleOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'assign-users',
            title: 'Assign Users',
            type: 'item',
            url: '/assign-users',
            icon: icons.AssignmentIndIcon,
            breadcrumbs: false
        },
        {
            id: 'super-admin-assign-users',
            title: 'Assign Users',
            type: 'item',
            url: '/super-admin-assign-users',
            icon: icons.AssignmentIndIcon,
            breadcrumbs: false
        },
        {
            id: 'add-announcement',
            title: 'Add Announcement',
            type: 'item',
            url: '/add-announcement',
            icon: icons.CampaignIcon,
            breadcrumbs: false
        },
        {
            id: 'super-admin-add-announcement',
            title: 'Add Announcement',
            type: 'item',
            url: '/super-admin-add-announcement',
            icon: icons.CampaignIcon,
            breadcrumbs: false
        },
        {
            id: 'super-admin-stages',
            title: 'All Stages',
            type: 'item',
            url: '/super-admin-stages',
            icon: icons.AutoGraphIcon,
            breadcrumbs: false
        },
        {
            id: 'super-admin-leads',
            title: 'All Leads',
            type: 'item',
            url: '/super-admin-leads',
            icon: icons.AssignmentIcon,
            breadcrumbs: false
        },
        // {
        //     id: 'stages',
        //     title: 'All Stages',
        //     type: 'item',
        //     url: '/stages',
        //     icon: icons.AutoGraphIcon,
        //     breadcrumbs: false
        // },
        {
            id: 'all-stages',
            title: 'Stages & Leads',
            type: 'item',
            url: '/all-stages',
            icon: icons.AssignmentIcon,
            breadcrumbs: false
        },
        {
            id: 'send-invoice',
            title: 'Invoices',
            type: 'item',
            url: '/send-invoice',
            icon: icons.ReceiptIcon,
            breadcrumbs: false
        },
        {
            id: 'payments',
            title: 'Payments',
            type: 'item',
            url: '/payments',
            icon: icons.PaymentsIcon,
            breadcrumbs: false
        },
        {
            id: 'chat-groups',
            title: 'Groups',
            type: 'item',
            url: '/chat-groups',
            icon: icons.PeopleOutlinedIcon,
            breadcrumbs: false
        }
    ]
};

export default pages;
